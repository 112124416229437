import SmkAsyncSelectGraphql from 'components/SmkAsyncSelect/customSelectGraphql';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { htmlDecode } from 'utils/helper';
import { RECIPE_SEARCH } from '../graphql/recipe.query';

const SubstituteCard = ({ filters, onChange, className, initialValues = [] }) => {
  const [substitutes, setSubstitutes] = useState(initialValues);

  useEffect(() => {
    if (!isEmpty(initialValues)) {
      setSubstitutes(initialValues);
    }
  }, [initialValues]);

  const updateStateAndNotify = (updatedData) => {
    setSubstitutes(updatedData);
    onChange(updatedData);
  };

  const handleSelectChange = (res) => {
    if (!substitutes.some((item) => item.id === res.id)) {
      const updatedData = [...substitutes, { name: res.name, id: res.id }];
      updateStateAndNotify(updatedData);
    }
  };

  const handleRemoveItem = (id) => {
    const updatedData = substitutes.filter((item) => item.id !== id);
    updateStateAndNotify(updatedData);
  };

  return (
    <Card>
      <CardBody className={className || ''}>
        <div className="mb-3">
          <CardTitle className="mb-3">Substitutes</CardTitle>
          <SmkAsyncSelectGraphql
            acceptedKey="name"
            acceptedValue="id"
            placeholder="Type Recipe name..."
            searchKeyName="name"
            query={RECIPE_SEARCH}
            queryName="admin_nutrition_recipe_search"
            dtoName="searchDTO"
            filters={{
              autoComplete: true,
              active: true,
              page: 1,
              size: 500,
              sortBy: 'id',
              sortOrder: 0,
              ...filters,
            }}
            onChange={handleSelectChange}
          />
        </div>

        {!isEmpty(substitutes) &&
          substitutes.map((item, index) => (
            <div
              key={item.id}
              className="bg-white rounded-2 p-3 d-flex justify-content-between align-items-center mt-3">
              <h6 className="mb-0">
                {index + 1}. {htmlDecode(item.name)}
                {!isEmpty(item.slug) && ` - ( ${htmlDecode(item.slug)} )`}
              </h6>
              <button className="btn btn-md btn-danger" type="button" onClick={() => handleRemoveItem(item.id)}>
                Remove
              </button>
            </div>
          ))}
      </CardBody>
    </Card>
  );
};

export default SubstituteCard;
