import { gql } from '@apollo/client';

// add Article categories
export const ADD_COMMUNITY_GROUP = gql`
  mutation Admin_community_group_add($createDTO: GroupCreateDTO!) {
    admin_community_group_add(createDTO: $createDTO) {
      id
      active
      deleted
    }
  }
`;

// update Article categories
export const UPDATE_COMMUNITY_GROUP = gql`
  mutation Admin_community_group_update($groupId: String!, $createDTO: GroupCreateDTO!) {
    admin_community_group_update(groupId: $groupId, createDTO: $createDTO) {
      id
      active
      deleted
    }
  }
`;
