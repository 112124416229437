import { gql } from '@apollo/client';

// recipe search results
export const RECIPE_SEARCH = gql`
  query Admin_nutrition_recipe_search($searchDTO: SearchRecipeDTO!) {
    admin_nutrition_recipe_search(searchDTO: $searchDTO) {
      success
      results {
        id
        deleted
        active
        createdDate
        updatedDate
        approved
        approvedDate
        approvedById
        name
        slug
        description
        language
        isPopular
        viewCount
        categories {
          id
          name
          slug
        }
        image {
          id
          name
          type
          filename
          thumbnailSmall
          thumbnailMedium
          thumbnailLarge
        }
        translations {
          language
          id
          title
          slug
        }
      }
      pagination {
        totalCount
        currentPage
        size
      }
    }
  }
`;

// recipe get by ID
export const RECIPE_GET_BY_ID = gql`
  query Admin_nutrition_recipe_getById($id: String!) {
    admin_nutrition_recipe_getById(id: $id) {
      id
      deleted
      active
      createdById
      updatedById
      createdDate
      updatedDate
      notes
      approved
      approvedDate
      approvedById
      name
      slug
      description
      language
      servesCount
      prepTime
      difficulty
      imageId
      isPopular
      viewCount
      createdBy {
        displayName
        designation
      }
      updatedBy {
        displayName
        designation
      }
      approvedBy {
        displayName
        designation
      }
      categories {
        id
        type
        name
        slug
        language
      }
      image {
        id
        name
        type
        size
        filename
        forType
        original
        thumbnailSmall
        thumbnailMedium
        thumbnailLarge
      }
      ingredients {
        recipeId
        foodItemId
        quantity
        quantityUnit
        preparationState
        condition
        isOptional
        foodItem {
          id
          name
          slug
          language
        }
        recipe {
          id
          name
          slug
          language
        }
        substitutes {
          id
          name
          slug
        }
      }
      nutritions {
        servingAmount
        servingWeight
        calories
        glycemicIndex
        protein {
          name
          value
          unit
          dv
        }
        carbohydrates_total {
          name
          value
          unit
          dv
        }
        carbohydrates_fiber {
          name
          value
          unit
          dv
        }
        carbohydrates_sugars_total {
          name
          value
          unit
          dv
        }
        carbohydrates_sugars_added {
          name
          value
          unit
          dv
        }
        fats_total {
          name
          value
          unit
          dv
        }
        fats_saturated {
          name
          value
          unit
          dv
        }
        fats_trans {
          name
          value
          unit
          dv
        }
        fats_monounsaturated {
          name
          value
          unit
          dv
        }
        fats_polyunsaturated {
          name
          value
          unit
          dv
        }
        vitamins {
          name
          value
          unit
          dv
        }
        minerals {
          name
          value
          unit
          dv
        }
        sodium {
          name
          value
          unit
          dv
        }
        cholesterol {
          name
          value
          unit
          dv
        }
      }
      instructions {
        equipmentsNeeded
        variationsAndSubstitutes
        servingSuggestions
        storageTips
        visualPresentation
        directions {
          step
          title
          description
          ingredientIds
        }
      }
      seo {
        title
        description
        keywords
        ogImageId
        ogImage {
          name
          type
          original
          thumbnailSmall
          thumbnailMedium
          thumbnailLarge
        }
      }
      translations {
        language
        id
        title
        slug
      }
    }
  }
`;
