import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';

// i18n and selectors
import { isLoggedInSelector, userSelector } from 'common/globalComponents/selectors';

// Menu components
import SchoolMyKidsMenuBar from './SchoolMyKidsMenuBar';
import TheParentZMenuBar from './TheParentZMenuBar';
import MediwatcherMenuBar from './MediwatcherMenuBar';
import { API_BASE_URL } from 'utils/constants';

// Redux state
const stateSelector = createStructuredSelector({
  isLoggedIn: isLoggedInSelector,
  user: userSelector,
});

// Function to dynamically load the logo based on app source and type
const getLogo = async (type, appSource) => {
  try {
    const logoPath = `../../assets/images/logo/${appSource}/${type}.png`;
    const importedLogo = await import(`../../assets/images/logo/${appSource}/${type}.png`);
    return importedLogo.default || logoPath;
  } catch (error) {
    return '../../assets/images/logo/schoolmykids/logo.png';
  }
};

// Component to render the logo
export const RenderLogo = ({ leftMenu, appSource }) => {
  const [logoSrc, setLogoSrc] = useState('');

  useEffect(() => {
    const loadLogo = async () => {
      const type = leftMenu ? 'small-logo' : 'logo';
      const src = await getLogo(type, appSource || 'schoolmykids');
      setLogoSrc(src);
    };

    loadLogo();
  }, [leftMenu, appSource]);

  return <img src={logoSrc} height="35" alt={`${appSource || 'schoolmykids'} logo`} />;
};

RenderLogo.propTypes = {
  leftMenu: PropTypes.bool.isRequired,
  appSource: PropTypes.string,
};

// Main Sidebar component
const Sidebar = (props) => {
  const { user, isLoggedIn } = useSelector(stateSelector);
  const appSource = user?.appSource || 'schoolmykids';

  return (
    <div className="vertical-menu">
      <div
        className={
          API_BASE_URL === 'https://www.schoolmykids.com/api/v2'
            ? 'bg-warning navbar-brand-box px-0'
            : 'navbar-brand-box px-0'
        }>
        <Link to="/" className="logo logo-dark">
          <span className="logo-sm">
            <RenderLogo leftMenu={true} appSource={appSource} />
          </span>
          <span className="logo-lg">
            <RenderLogo leftMenu={false} appSource={appSource} />
          </span>
        </Link>

        <Link to="/" className="logo logo-light">
          <span className="logo-sm">
            <RenderLogo leftMenu={true} appSource={appSource} />
          </span>
          <span className="logo-lg">
            <RenderLogo leftMenu={false} appSource={appSource} />
          </span>
        </Link>
      </div>

      <div data-simplebar className="h-100">
        {appSource === 'schoolmykids' && <SchoolMyKidsMenuBar />}
        {appSource === 'theparentz' && <TheParentZMenuBar />}
        {appSource === 'mediwatcher' && <MediwatcherMenuBar />}
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  leftMenu: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  layout: state.Layout,
});

export default connect(mapStateToProps, {})(withRouter(withTranslation()(Sidebar)));
