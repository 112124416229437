import CustomImageUploader from 'components/CustomImageUploader';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { MODULE_TYPE } from 'utils/constants';

const InstructionsCard = ({ formik, formikLabel, values }) => {
  toast.configure();

  const defaultDirection = {
    step: undefined,
    title: '',
    description: '',
    image: null,
    ingredientIds: [],
    utensils: [],
  };

  const defaultInstructions = {
    directions: [defaultDirection],
    equipmentsNeeded: '',
    variationsAndSubstitutes: '',
    servingSuggestions: '',
    storageTips: '',
    visualPresentation: '',
  };

  const [instructions, setInstructions] = useState(defaultInstructions);

  // Set Formik values when component mounts or when values change
  useEffect(() => {
    if (!isEmpty(values)) {
      setInstructions(values);
    }
  }, [values]);

  useEffect(() => {
    formik.setFieldValue(formikLabel, instructions);
  }, [instructions]);

  const handleInputChange = (e, key) => {
    const { value } = e.target;

    // Update the singular fields in instructions
    setInstructions((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleDirectionInputChange = (e, key, index) => {
    let updatedvalue;
    if (key === 'image' || key === 'utensils') {
      updatedvalue = e;
    } else {
      updatedvalue = key === 'step' ? Number(e.target.value) : e.target.value;
    }

    // Update directions state
    const updatedDirections = instructions.directions.map((direction, i) =>
      i === index ? { ...direction, [key]: updatedvalue } : direction
    );

    setInstructions((prev) => ({
      ...prev,
      directions: updatedDirections,
    }));
  };

  const handleAddDirection = () => {
    const updatedDirections = [...instructions.directions, defaultDirection];
    setInstructions((prev) => ({
      ...prev,
      directions: updatedDirections,
    }));
  };

  const handleDeleteDirection = (index) => {
    const updatedDirections = instructions.directions.filter((_, i) => i !== index);
    setInstructions((prev) => ({
      ...prev,
      directions: updatedDirections,
    }));
  };

  return (
    <Card>
      <CardBody>
        <h4 className="mb-3">Instructions</h4>
        <div>
          <Row>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="equipmentsNeeded">Equipments Needed</Label>
                <Input
                  type="textarea"
                  rows={3}
                  id="equipmentsNeeded"
                  placeholder="Equipments Needed"
                  value={instructions.equipmentsNeeded}
                  onChange={(e) => handleInputChange(e, 'equipmentsNeeded')}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="variationsAndSubstitutes">Variations and Substitutes</Label>
                <Input
                  type="textarea"
                  rows={3}
                  id="variationsAndSubstitutes"
                  placeholder="Variations and Substitutes"
                  value={instructions.variationsAndSubstitutes}
                  onChange={(e) => handleInputChange(e, 'variationsAndSubstitutes')}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="servingSuggestions">Serving Suggestions</Label>
                <Input
                  type="textarea"
                  rows={3}
                  id="servingSuggestions"
                  placeholder="Serving Suggestions"
                  value={instructions.servingSuggestions}
                  onChange={(e) => handleInputChange(e, 'servingSuggestions')}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="storageTips">Storage Tips</Label>
                <Input
                  type="textarea"
                  rows={3}
                  id="storageTips"
                  placeholder="Storage Tips"
                  value={instructions.storageTips}
                  onChange={(e) => handleInputChange(e, 'storageTips')}
                />
              </FormGroup>
            </Col>

            <Col md={12}>
              <FormGroup className="mb-4">
                <Label for="visualPresentation">Visual Presentation</Label>
                <Input
                  type="textarea"
                  rows={3}
                  id="visualPresentation"
                  placeholder="Visual Presentation"
                  value={instructions.visualPresentation}
                  onChange={(e) => handleInputChange(e, 'visualPresentation')}
                />
              </FormGroup>
            </Col>

            {/* Directions Section */}

            <FormGroup className="mb-4">
              <Label for="directions">Directions</Label>
              {instructions.directions.map((direction, index) => (
                <div key={index} className="bg-light card shadow-md border p-3 rounded-3 mb-4">
                  <div className="bg-white py-2 px-3 w-100 text-center  mb-3 border rounded-2 d-flex justify-content-between align-items-center">
                    <h4 className="mb-0">Step {index + 1}</h4>
                    <Button color="danger" onClick={() => handleDeleteDirection(index)}>
                      Delete
                    </Button>
                  </div>
                  <FormGroup className="mb-4">
                    <Label for={`step-${index}`}>Step</Label>
                    <Input
                      type="number"
                      id={`step-${index}`}
                      placeholder="Step"
                      value={direction.step || ''}
                      onChange={(e) => handleDirectionInputChange(e, 'step', index)}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label for={`title-${index}`}>Title</Label>
                    <Input
                      type="text"
                      id={`title-${index}`}
                      placeholder="Title"
                      value={direction.title}
                      onChange={(e) => handleDirectionInputChange(e, 'title', index)}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label for={`description-${index}`}>Description</Label>
                    <Input
                      type="textarea"
                      rows={3}
                      id={`description-${index}`}
                      placeholder="Description"
                      value={direction.description}
                      onChange={(e) => handleDirectionInputChange(e, 'description', index)}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label for={`utensils-${index}`}>utensils</Label>
                    <CreatableSelect
                      isMulti
                      placeholder="Type utensils name.."
                      value={direction.utensils}
                      onChange={(res) => handleDirectionInputChange(res, 'utensils', index)}
                      options={[]}
                    />
                  </FormGroup>

                  <CustomImageUploader
                    label={'Select Image'}
                    forType={MODULE_TYPE.METADATA}
                    value={direction.image}
                    onChange={(e) => handleDirectionInputChange(e, 'image', index)}
                    onReset={() => handleDirectionInputChange(null, 'image', index)}
                  />
                </div>
              ))}
              <Button color="primary" onClick={handleAddDirection}>
                Add Direction
              </Button>
            </FormGroup>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

export default InstructionsCard;
