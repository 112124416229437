import { Divider } from '@material-ui/core';
import Breadcrumb from 'components/Common/Breadcrumb';
import InputSlug from 'components/EditSlug/InputSlug';
import Loader from 'components/Loader';
import TableError from 'components/TableError';
import { useFormik } from 'formik';
import useRequest from 'hooks/useRequest';
import { useRouter } from 'hooks/useRouter';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import {
  LANGUAGE_ARRAY_WITH_STRING,
  LANGUAGE_VALUES_STRING,
  PRINT_LANGUAGE,
  PRINT_LANGUAGE_COLOR,
} from 'utils/constants';
import { checkIfValueExist, printDate, urlSlugify } from 'utils/helper';
import * as Yup from 'yup';

const SubjectTopic = () => {
  const router = useRouter();
  const history = useHistory();
  const [subjectTopics, setsubjectTopics] = useState([]);
  const [parentTopicsList, setParentTopicsList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [showAddTopicModal, setShowAddTopicModal] = useState(false);
  const [currentSubjectObj, setcurrentSubjectObj] = useState('');
  const [currentTopicObj, setcurrentTopicObj] = useState('');
  const [isEditTopic, setIsEditTopic] = useState({});
  toast.configure();

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    id: '',
    subjectId: '',
    parentTopicId: '',
    name: '',
    language: LANGUAGE_VALUES_STRING?.english,
  };

  const [filters, setFilters] = useState(defaultFilters);

  const defaultPayload = {
    size: 1500,
    sortBy: 'id',
    sortOrder: 0,
  };

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };
  const [getSubjectsList, { loading: getSubjectsListLoading }] = useRequest({
    method: 'POST',
    url: `/curriculum/admin/subject/search`,
    onSuccess: (data) => {
      const sortedData = data?.results?.sort((a, b) => a?.order - b?.order);
      setSubjectList(sortedData);
      setDataList(sortedData);
    },
  });
  const [topicApprovedRequest, { loading: topicApprovedRequestLoading }] = useRequest({
    onSuccess: (data) => {
      toast.success(`Success! ${data?.id} Topic has been ${data?.active ? 'Approved' : 'UnApproved'}`);
      resetData();
    },
    onError: (err) => {
      toast.error(`Error! ${err?.message?.message}`);
    },
  });
  const [addSubjectTopicRequest, { loading: addSubjectTopicRequestLoading }] = useRequest({
    onSuccess: (data) => {
      setShowAddTopicModal(!showAddTopicModal);
      toast.success(`Success! Topic has been ${!isEditTopic ? 'added' : 'Edited'}`);
      resetData();
    },
    onError: (err) => {
      toast.error(`Error! ${err?.message?.message}`);
    },
  });
  const [getWorksheetListRequest, { loading: getWorksheetListLoading }] = useRequest({
    url: `/curriculum/admin/subjectTopic/search`,
    method: 'POST',
    onSuccess: (data) => {
      setsubjectTopics(data?.results);

      let topics = data?.results.reduce((acc, curr) => {
        let index = acc.findIndex((el) => el.parentTopics == curr.parentTopics);
        if (index == -1) {
          acc.push(curr);
        }
        return acc;
      }, []);
      setParentTopicsList(topics);
    },
  });

  const handleChangePage = (page) => {
    const payload = {
      page,
      ...defaultPayload,
      ...filters,
      active: checkIfValueExist(filters.active?.value),
      deleted: checkIfValueExist(filters.deleted?.value),
      subjectId: checkIfValueExist(filters.subjectId),
      parentTopicId: checkIfValueExist(filters.parentTopicId),
    };

    setPageInUrl(page);

    getWorksheetListRequest({
      body: {
        ...payload,
        ...defaultPayload,
      },
    });

    getSubjectsList({
      body: {
        page: 1,
        language: filters.language,
        ...defaultPayload,
      },
    });
  };

  const resetData = () => {
    const payload = {
      page: 1,
      ...defaultPayload,
      language: defaultFilters?.language,
    };
    getWorksheetListRequest({
      body: {
        ...payload,
      },
    });
    getSubjectsList({
      body: {
        ...payload,
      },
    });
  };

  const clearFilters = () => {
    setPageInUrl(1);
    setFilters(defaultFilters);
    resetData();
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  useEffect(() => {
    resetData();
  }, []);

  const handleApproved = (currentObj) => {
    topicApprovedRequest({
      url: `/curriculum/admin/subjectTopic/${currentObj?.id}`,
      method: 'PUT',
      body: {
        active: isEmpty(currentObj?.approvedById) ? true : false,
      },
    });
  };

  // useEffect(() => {
  //   getWorksheetListRequest({
  //     body: {
  //       page: 1,
  //       size: 1500,
  //       active: checkIfValueExist(filters.active?.value),
  //       deleted: checkIfValueExist(filters.deleted?.value),
  //       name: checkIfValueExist(filters?.name),
  //       id: checkIfValueExist(filters?.id),
  //       subjectId: checkIfValueExist(filters?.subjectId),
  //       parentTopicId: checkIfValueExist(filters?.parentTopicId),
  //       language: filters?.language,
  //       ...defaultPayload,
  //     },
  //   });
  // }, [filters?.language]);

  const printTable = (data) => {
    return (
      <>
        {data[0]?.subject?.name && (
          <div className="bg-white d-flex px-4 py-3 mb-2">
            {data[0]?.subject?.name && (
              <h5 className="mb-0 me-4 pe-3">
                Subject :{' '}
                <span className="h5 text-primary">
                  {data[0]?.subject?.name} ({data[0]?.subject?.id})
                </span>
              </h5>
            )}
            {data[0]?.subject?.name && (
              <h5 className="mb-0 me-4 pe-3">
                parentTopic :{' '}
                <span className="h5 text-primary">
                  {data[0]?.parentTopic?.name} {data[0]?.parentTopic?.id ? '(' + data[0]?.parentTopic?.id + ')' : ''}
                </span>
              </h5>
            )}
            {data[0]?.subject?.name && (
              <h5 className="mb-0 me-4 pe-3">
                rootTopic :{' '}
                <span className="h5 text-primary">
                  {data[0]?.rootTopic?.name} {data[0]?.rootTopic?.id ? '(' + data[0]?.rootTopic?.id + ')' : ''}
                </span>
              </h5>
            )}
          </div>
        )}
        <Table className="project-list-table table-nowrap table-centered table-borderless">
          <thead>
            <tr className="table-warning">
              <th scope="col">#Id</th>
              <th scope="col">Name</th>
              <th scope="col">Worksheet Count</th>
              <th scope="col">Approved</th>
              <th scope="col">Language</th>
              <th scope="col">Created On</th>
              <th scope="col">Updated On</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            {!isEmpty(data) ? (
              data?.map((category, index) => (
                <tr key={index} className={category?.deleted ? 'bg-soft-danger' : ''}>
                  <td>
                    {category?.id}
                    <br />
                    <Badge className={category?.active ? 'bg-success' : 'bg-danger'}>
                      {category?.active ? 'A' : 'IA'}
                    </Badge>
                  </td>
                  <td>
                    <div
                      style={{ cursor: 'pointer' }}
                      className="text-primary"
                      onClick={() => {
                        setShowAddTopicModal(!showAddTopicModal);
                        setIsEditTopic(category);
                      }}>
                      {category?.name} <br />
                    </div>
                    {category?.slug}

                    <Badge className={'bg-light'}>{category?.frontend_app_name}</Badge>
                  </td>
                  <td>{category?.worksheet_count}</td>

                  <td align="center">
                    <Badge className={category?.approvedById !== null ? 'bg-success' : 'bg-danger'}>
                      {category?.approvedById !== null ? 'Yes' : 'No'}
                    </Badge>
                  </td>
                  <td align="center">
                    <Badge className={PRINT_LANGUAGE_COLOR[category?.language]}>
                      {PRINT_LANGUAGE[category?.language]}
                    </Badge>
                  </td>
                  <td>{printDate(category?.createdDate)} </td>

                  <td>{printDate(category?.updatedDate)} </td>

                  <td>
                    <UncontrolledDropdown>
                      <DropdownToggle href="#" className="card-drop " tag="i">
                        <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem onClick={() => handleApproved(category)}>
                          <i
                            className={`fas fa-power-off ${
                              !isEmpty(category?.approvedById) ? 'text-danger' : 'text-success'
                            } m-1 pe-3`}
                          />
                          {!isEmpty(category?.approvedById) ? 'UnApproved' : 'Approved'}
                        </DropdownItem>
                        <Divider className="my-2" />
                        <DropdownItem
                          onClick={() => {
                            setShowAddTopicModal(!showAddTopicModal);
                            setIsEditTopic(category);
                          }}>
                          <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                          Edit
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))
            ) : (
              <TableError />
            )}
          </tbody>
        </Table>
      </>
    );
  };

  // states
  const [treeDataList, setTreeDataList] = useState([]);
  const [subjectTopicListData, setSubjectTopicListData] = useState([]);
  const [currentTab, setCurrentTab] = useState('');
  const [currentTabId, setCurrentTabId] = useState('');
  const [dataList, setDataList] = useState([]);

  const subjectTopicChildrens = (elem) => {
    if (isEmpty(elem)) return;
    if (!isEmpty(elem)) {
      const filter2 = (e) =>
        subjectTopics?.filter((t) => t.parentTopicId == e?.id)?.sort((a, b) => a?.order - b?.order);

      return filter2(elem)?.map((e, index) => ({
        key: index + 10,
        id: e?.id,
        label: e?.name,
        children: subjectTopicChildrens(e),
      }));
    }
  };

  useEffect(() => {
    if (isEmpty(subjectList) || isEmpty(subjectTopics)) {
      setTreeDataList([]);
      return;
    }

    const buildTreeData = (subjects, topics) => {
      return subjects.map((subject, index) => {
        const filteredTopics = topics
          ?.filter((topic) => topic?.subject?.slug === subject?.slug && !topic.parentTopic)
          ?.sort((a, b) => a?.order - b?.order);

        const children = filteredTopics?.map((topic, childIndex) => ({
          key: childIndex + 10,
          id: topic?.id,
          label: topic?.name,
          children: subjectTopicChildrens(topic),
        }));

        return {
          key: index,
          id: subject?.id,
          label: subject?.name,
          children,
        };
      });
    };

    const treeData = buildTreeData(subjectList, subjectTopics);
    setTreeDataList(treeData);
  }, [subjectList, subjectTopics]);

  function Tree({ treeData, firstParam = false }) {
    return (
      <ul className="px-0">
        {!isEmpty(treeData) && treeData.map((node) => <TreeNode node={node} key={node.key} firstParam={firstParam} />)}
      </ul>
    );
  }

  const fetchData = (children) => {
    let tempVar = false;
    if (isEmpty(children)) {
      return false;
    }
    if (!isEmpty(children)) {
      for (let i = 0; i < children.length; i++) {
        if (children[i]?.label == currentTab && children[i].id == currentTabId) {
          return true;
        } else {
          if (fetchData(children[i]?.children)) {
            tempVar = true;
            break;
          }
        }
      }
      return tempVar;
    }
  };

  function TreeNode({ node, firstParam }) {
    const { children, label, id } = node;
    const [showChildren, setShowChildren] = useState(currentTab == label || fetchData(children));

    const updateData = () => {
      const tempObj = [];
      children?.filter((e) => tempObj?.push(subjectTopics?.find((data) => data?.id == e?.id)));

      const sortedData = tempObj?.sort((a, b) => a?.order - b?.order);
      setSubjectTopicListData(sortedData);
    };

    const handleClick = (currentLabel) => {
      setShowChildren(!showChildren);
      setCurrentTab(currentLabel);
      setCurrentTabId(id);
      updateData();
      setcurrentTopicObj('');
      setIsEditTopic({});

      if (firstParam) {
        setcurrentSubjectObj({
          label,
          id,
        });
      }

      if (isEmpty(children)) {
        setDataList([]);
        setShowAddTopicModal(!showAddTopicModal);
        setcurrentTopicObj({
          label,
          id,
        });
      }
    };

    // useEffect(() => {
    //   if (router?.query?.subject) {
    //     if (router?.query?.subject == id) {
    //       handleClick(label)
    //     }
    //   }
    // }, [router?.query?.subject])

    return (
      <>
        <div onClick={() => handleClick(label)} style={{ marginBottom: '10px', cursor: 'pointer' }}>
          <div className="d-flex justify-content-between bg-white pt-2 px-3">
            <span className={`${isEmpty(children) ? 'mb-2' : 'mb-0 h6'}`}>
              {label} - {id}
            </span>
            {!isEmpty(children) && <i className="bx bx-chevron-down h3" />}
          </div>
        </div>
        <ul style={{ borderLeft: '1px solid black' }}>{showChildren && <Tree treeData={children} />}</ul>
      </>
    );
  }

  useEffect(() => {
    if (!isEmpty(subjectTopicListData)) {
      setDataList(subjectTopicListData);
    } else {
      setDataList([]);
    }
  }, [subjectTopicListData]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: {
      name: !isEmpty(isEditTopic) ? isEditTopic?.name : '',
      slug: !isEmpty(isEditTopic) ? isEditTopic?.slug : '',
      order: !isEmpty(isEditTopic) ? isEditTopic?.order : 0,
      active: !isEmpty(isEditTopic) ? isEditTopic?.active : true,
      deleted: !isEmpty(isEditTopic) ? isEditTopic?.deleted : false,
    },
    onSubmit: (values, action) => {
      const addPayload = {
        name: values?.name,
        order: values?.order,
        slug: handleRefreshSlug(),
        subjectId: !isEmpty(dataList[0]?.subject)
          ? dataList[0]?.subject?.id
          : !isEmpty(currentSubjectObj)
          ? currentSubjectObj?.id
          : undefined,
        parentTopicId: !isEmpty(dataList[0]?.parentTopic)
          ? dataList[0]?.parentTopic?.id
          : !isEmpty(currentTopicObj)
          ? currentTopicObj?.id
          : undefined,
      };
      const editPayload = {
        name: values?.name,
        slug: values?.slug,
        order: values?.order,
        active: values?.active,
        deleted: values?.deleted,
      };

      if (!isEmpty(isEditTopic)) {
        addSubjectTopicRequest({
          url: `/curriculum/admin/subjectTopic/${isEditTopic?.id}`,
          method: 'PUT',
          body: {
            ...editPayload,
          },
        });
      } else {
        addSubjectTopicRequest({
          url: `/curriculum/admin/subjectTopic/admin-subject-topic-add`,
          method: 'POST',
          body: {
            ...addPayload,
          },
        });
      }
      action.resetForm();
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please provide name'),
      order: Yup.number()
        .moreThan(-1, 'order is greater than 100')
        .lessThan(100, 'order is less than 100')
        .required('Required'),
    }),
  });

  const handleAddTopic = () => {
    if (isEmpty(dataList[0]?.subject)) {
      toast.error('Error! Please choose one subject...');
    } else {
      setIsEditTopic({});
      setShowAddTopicModal(!showAddTopicModal);
    }
  };

  const handleRefreshSlug = () => {
    const generateSlug = urlSlugify(formik?.values?.name);

    const isEditGeneratedSlug = urlSlugify(formik?.values?.name);

    if (!isEmpty(isEditTopic)) {
      formik.setFieldValue('slug', isEditGeneratedSlug);
      return isEditGeneratedSlug;
    } else {
      formik.setFieldValue('slug', generateSlug);
      return generateSlug;
    }
  };

  return (
    <div>
      <Loader
        isActive={
          getWorksheetListLoading ||
          getSubjectsListLoading ||
          addSubjectTopicRequestLoading ||
          topicApprovedRequestLoading
        }
      />

      <div className="page-content">
        <Breadcrumb title="Worksheets" breadcrumbItem="Worksheets Subject Topic List" />
        <Card>
          <CardBody>
            <div className="mt-4">
              <div className="row">
                <div className="col-lg-2 mb-4">
                  <label className="form-label">Active</label>
                  <Select
                    options={[
                      { value: null, label: 'All' },
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' },
                    ]}
                    value={filters.active}
                    onChange={(value) => {
                      setFilters({ ...filters, active: value });
                      if (value === null) {
                        const newFilters = { ...filters };
                        delete newFilters.active;
                        setFilters(newFilters);
                      }
                    }}
                  />
                </div>
                <div className="col-lg-2 mb-4">
                  <label className="form-label">Deleted</label>
                  <Select
                    options={[
                      { value: null, label: 'All' },
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' },
                    ]}
                    value={filters.deleted}
                    onChange={(value) => setFilters({ ...filters, deleted: value })}
                  />
                </div>

                <div className="col-lg-2 mb-4">
                  <label className="form-label">Language</label>
                  <Select
                    options={LANGUAGE_ARRAY_WITH_STRING}
                    value={LANGUAGE_ARRAY_WITH_STRING.find((t) => t.value == filters.language)}
                    onChange={(item) =>
                      setFilters({
                        ...filters,
                        language: item.value,
                      })
                    }
                  />
                </div>

                <div className="col-lg-2 mb-4">
                  <label className="form-label">Subjects</label>
                  <Select
                    options={subjectList?.map((s) => ({
                      ...s,
                      value: s.id,
                      label: s.name,
                    }))}
                    value={subjectList
                      .filter((t) => t.id == filters.subjectId)
                      ?.map((s) => ({
                        ...s,
                        value: s.id,
                        label: s.name,
                      }))}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        subjectId: parseInt(e.value),
                      })
                    }
                  />
                </div>

                <div className="col-lg-3 mb-4">
                  <label className="form-label">Parent Topics</label>
                  <Select
                    options={parentTopicsList?.map((s) => ({
                      ...s,
                      value: s.id,
                      label: s.name,
                    }))}
                    value={filters.parentTopicsList && parentTopicsList.find((t) => t.id == filters.id)}
                    onChange={(item) =>
                      setFilters({
                        ...filters,
                        parentTopicId: item.parentTopicId,
                      })
                    }
                  />
                </div>

                <div className="col-lg-3 mb-4">
                  <label className="form-label">Parent Topics Id</label>
                  <Input
                    type="text"
                    name="name"
                    placeholder="parent topic id..."
                    value={filters.parentTopicI}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        parentTopicId: parseInt(e.target.value),
                      })
                    }
                  />
                </div>

                <div className="col-lg-3 mb-4">
                  <label className="form-label">Name</label>
                  <Input
                    type="text"
                    name="name"
                    placeholder="name..."
                    value={filters.name}
                    onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                  />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <a className="btn btn-primary me-4" onClick={applyFilters}>
                Apply Filters
              </a>
              <a className="" onClick={clearFilters}>
                Clear
              </a>
            </div>
          </CardBody>
        </Card>

        <Container fluid>
          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{dataList?.length} Topics Found</div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Button
                  onClick={handleAddTopic}
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2">
                  <i className="mdi mdi-plus me-1" />
                  Add Topic
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <br />
              <Tree treeData={treeDataList} firstParam={true} />
            </Col>
            <Col md={8}>
              <div className="table-responsive" style={{ minHeight: 300 }}>
                {printTable(dataList)}
              </div>
            </Col>
          </Row>

          {/* <Paginate pageInfo={pagination} onChangePage={handleChangePage} /> */}
        </Container>
      </div>

      <Modal
        isOpen={showAddTopicModal}
        role="dialog"
        autoFocus
        centered
        className=""
        tabIndex="-1"
        toggle={() => {
          setShowAddTopicModal(!showAddTopicModal);
        }}>
        <div className="modal-content">
          <ModalHeader
            className="modalHeader"
            toggle={() => {
              setShowAddTopicModal(!showAddTopicModal);
            }}>
            {isEmpty(isEditTopic) ? 'Add' : 'Edit'} Topic
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={formik.handleSubmit}>
              <FormGroup className="mb-4">
                <Label htmlFor="name" className="form-label  ">
                  Name
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="Enter name..."
                  id="name"
                  invalid={!!(formik.touched.name && formik.errors.name)}
                  {...formik.getFieldProps('name')}
                />
                <FormFeedback className="d-block">{formik.errors.name}</FormFeedback>
              </FormGroup>

              <Row>
                <Col md={isEmpty(isEditTopic) ? 6 : 4}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="order" className="form-label  ">
                      Order
                    </Label>
                    <Input
                      type="number"
                      className="form-control"
                      placeholder="Enter order..."
                      id="order"
                      invalid={!!(formik.touched.order && formik.errors.order)}
                      {...formik.getFieldProps('order')}
                    />
                    <FormFeedback className="d-block">{formik.errors.order}</FormFeedback>
                  </FormGroup>
                </Col>
                {!isEmpty(isEditTopic) && (
                  <>
                    <Col md={4}>
                      <CardTitle className="mb-3">Active</CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`active_${option}`}
                            name="active"
                            className="form-check-input"
                            checked={formik.values.active === option}
                            onChange={() => formik.setFieldValue('active', option)}
                          />
                          <label className="form-check-label" htmlFor={`active_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </Col>
                    <Col md={4}>
                      <CardTitle className="mb-3">Delete </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`deleted_${option}`}
                            name="deleted"
                            className="form-check-input"
                            checked={formik.values.deleted === option}
                            onChange={() => formik.setFieldValue('deleted', option)}
                          />
                          <label className="form-check-label" htmlFor={`deleted_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </Col>
                  </>
                )}
              </Row>

              <FormGroup className="mb-4">
                <Label htmlFor="slug" className="form-label  ">
                  Slug
                </Label>
                <Row>
                  <Col sm={9} className="pe-0">
                    <InputSlug formik={formik} />
                  </Col>
                  <Col sm={3}>
                    <Button color="primary" className="w-100" onClick={handleRefreshSlug}>
                      Refresh
                    </Button>
                  </Col>
                </Row>

                <FormFeedback className="d-block">{formik.errors.slug}</FormFeedback>
              </FormGroup>

              {!isEmpty(isEditTopic) && (
                <h5 className="mb-3">
                  Id : <span className="h5 text-primary">{isEditTopic?.id}</span>
                </h5>
              )}
              {!isEmpty(currentSubjectObj) && (
                <h5 className="mb-3">
                  Subject : <span className="h5 text-primary">{currentSubjectObj?.label}</span>
                </h5>
              )}
              {dataList[0]?.parentTopic?.name && (
                <h5 className="mb-3">
                  parentTopic : <span className="h5 text-primary">{dataList[0]?.parentTopic?.name}</span>
                </h5>
              )}
              {!isEmpty(currentTopicObj) && (
                <h5 className="mb-3">
                  parentTopic : <span className="h5 text-primary">{currentTopicObj?.label}</span>
                </h5>
              )}

              <div className="text-end my-4">
                <Button type="submit" color="success">
                  Save
                </Button>
              </div>
            </Form>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};

export default SubjectTopic;
