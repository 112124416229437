import { gql } from '@apollo/client';

// add recipe
export const ADD_RECIPE = gql`
  mutation Admin_nutrition_recipe_add($recipeDTO: CreateRecipeDTO!) {
    admin_nutrition_recipe_add(recipeDTO: $recipeDTO) {
      id
      active
      deleted
    }
  }
`;

// update recipe
export const UPDATE_RECIPE = gql`
  mutation Admin_nutrition_recipe_update($id: String!, $recipeDTO: CreateRecipeDTO!) {
    admin_nutrition_recipe_update(id: $id, recipeDTO: $recipeDTO) {
      id
      active
      deleted
    }
  }
`;
