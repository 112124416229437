import { isArray, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';

const MultipleNutritions = ({ index, formikKey, values, setLocalValues }) => {
  const defaultUnits = {
    unit: 'g',
    name: '',
    dv: undefined,
    value: undefined,
  };

  const [nutritionData, setNutritionData] = useState([defaultUnits]);

  const handleLocalChange = (index, key, value) => {
    const updatedNutritionData = nutritionData?.map((item, i) => (i === index ? { ...item, [key]: value } : item));
    setNutritionData(updatedNutritionData);
  };

  const addNutrition = () => {
    setNutritionData([...nutritionData, { value: '', unit: '', dv: '' }]);
  };

  const deleteNutrition = (index) => {
    setNutritionData(nutritionData?.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (!isEmpty(values) && isArray(values)) {
      setNutritionData(values);
    }
  }, [values]);

  useEffect(() => {
    setLocalValues((prev) => ({
      ...prev,
      [formikKey.value]: nutritionData,
    }));
  }, [nutritionData]);

  return (
    <>
      <Label>
        {index}. {formikKey.label}
      </Label>
      <div className="card p-4 bg-light">
        <div className="card-body">
          {!isEmpty(nutritionData) &&
            nutritionData?.map((nutrition, index) => (
              <Row key={index} className="align-items-center mb-3">
                <Col md={2}>
                  <Label>{index + 1}.</Label>
                </Col>
                <Col md={10} className="d-flex justify-content-start">
                  <div className="d-flex align-items-center" style={{ gap: 20 }}>
                    <Input
                      type="text"
                      placeholder="Name"
                      value={nutrition.name || ''}
                      onChange={(e) => handleLocalChange(index, 'name', e.target.value)}
                    />
                    <Input
                      type="number"
                      step="0.01"
                      placeholder="Value"
                      value={nutrition.value || ''}
                      onChange={(e) => handleLocalChange(index, 'value', parseFloat(e.target.value) || '')}
                    />
                    <select
                      className="form-select"
                      value={nutrition.unit || ''}
                      onChange={(e) => handleLocalChange(index, 'unit', e.target.value)}>
                      <option value={'g'}>Gram (g)</option>
                      <option value={'mg'}>Miligram (mg)</option>
                      <option value={'mcg'}>Microgram (mcg)</option>
                    </select>

                    <Input
                      type="number"
                      step="0.01"
                      placeholder="DV"
                      value={nutrition.dv || ''}
                      onChange={(e) => handleLocalChange(index, 'dv', parseFloat(e.target.value) || '')}
                    />
                    <Button color="danger" onClick={() => deleteNutrition(index)}>
                      Delete
                    </Button>
                  </div>
                </Col>
              </Row>
            ))}
          <Button color="primary" onClick={addNutrition} className="mt-3">
            Add {formikKey.label}
          </Button>
        </div>
      </div>
    </>
  );
};

export default MultipleNutritions;
