import Branding from './Branding';
import Login from './Login';
import LOGIN_LINKS from './links';

export default [
  {
    path: LOGIN_LINKS.LOGIN_PAGE,
    exact: true,
    component: Login,
  },
];
