import { isEmpty } from 'lodash';
import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { Card, CardBody, Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import AssetImageUploader from './AssetImageUploader';

const SeoMetaData = ({ formik, forType }) => {
  return (
    <Card className="mt-5">
      <CardBody>
        <h5 className="border-bottom pb-3">SEO Meta data Details</h5>
        <Row className="mt-4">
          <Col md={12}>
            <FormGroup className="mb-4">
              <Label for="seoTitle" className="form-label">
                Title
              </Label>
              <Input
                id="seoTitle"
                name="seoTitle"
                type="text"
                className="form-control"
                placeholder="SEO Title..."
                invalid={!!(formik.touched.seoTitle && formik.errors.seoTitle)}
                {...formik.getFieldProps('seoTitle')}
              />
              <FormFeedback>{formik.errors.seoTitle}</FormFeedback>
            </FormGroup>
          </Col>

          <Col md={12}>
            <FormGroup className="mb-4">
              <Label for="seoKeywords" className="form-label">
                Keywords
              </Label>
              <CreatableSelect
                isMulti
                placeholder="Type keyword name.."
                value={formik.values.seoKeywords}
                onChange={(res) => {
                  formik.setFieldValue('seoKeywords', res);
                }}
                options={[]}
              />
              <FormFeedback>{formik.errors.seoKeywords}</FormFeedback>

              {!isEmpty(formik?.values?.seoKeywords) && (
                <div className="mt-3">
                  Keywords:{' '}
                  <span>
                    <b>{formik?.values?.seoKeywords?.map((e) => e?.value)?.join(', ')}</b>
                  </span>
                </div>
              )}
            </FormGroup>
          </Col>

          <Col md={12}>
            <FormGroup className="mb-4">
              <Label for="seoDescription" className="form-label">
                Description
              </Label>
              <Input
                rows={5}
                id="seoDescription"
                name="seoDescription"
                type="textarea"
                className="form-control"
                placeholder="SEO Description..."
                invalid={!!(formik.touched.seoDescription && formik.errors.seoDescription)}
                {...formik.getFieldProps('seoDescription')}
              />
              <FormFeedback>{formik.errors.seoDescription}</FormFeedback>
            </FormGroup>
          </Col>

          <Col md={12}>
            <AssetImageUploader forType={forType} formik={formik} fieldName={'ogImage'} label={'Select OG Image'} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default SeoMetaData;
