import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import AuthService from '../../common/auth.service';
import { isLoadingSelector, isLoggedInSelector } from '../../common/globalComponents/selectors';
import Loader from '../../components/Loader';
import { getUser } from '../../containers/authentication/actions';
import { SMKLinks } from '../../utils/links';
import { useRouter } from 'hooks/useRouter';

const stateSelector = createStructuredSelector({
  isLoading: isLoadingSelector,
  isLoggedIn: isLoggedInSelector,
});

const Authmiddleware = (props) => {
  const { component: Component, layout: Layout, isAuthProtected, ...rest } = props;

  const router = useRouter();
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading, isLoggedIn } = useSelector(stateSelector);

  useEffect(() => {
    if (!isLoggedIn) {
      const auth = new AuthService();
      const token = auth.getSessionCookie();
      if (router?.pathname !== '/branding') {
        if (!token) {
          history.replace(SMKLinks.LOGIN_PAGE);
        } else {
          dispatch(getUser.trigger());
        }
      }
    }
  }, [isLoggedIn]);

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          (!isAuthProtected || (isLoggedIn && isAuthProtected)) && (
            <Layout>
              <Loader isActive={isLoading} />
              <Component {...props} />
            </Layout>
          )
        );
      }}
    />
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;
