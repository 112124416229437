import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';

// Redux
import { useDispatch } from 'react-redux';

import { EMAIL_REGEX, SOURCE } from '../../utils/constants';

import profileImg from '../../assets/images/profile-img.png';
import { doLogin } from './actions';
import GoogleLoginComponent from './googleLogin';
import { getCurrentUserData, titleCaseIfExists } from 'utils/helper';

const Login = (props) => {
  const dispatch = useDispatch();

  const [userType, setUserType] = useState(SOURCE[0]?.value);
  const [showHidePassword, setShowHidePassword] = useState(false);

  const loginErrorMsg = '';

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: false,
    },
    onSubmit(values) {
      dispatch(doLogin.trigger({ email: values.email, password: values.password, userType }));
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required('Please provide email')
        .test('email', 'Invalid Email', (val) => val && EMAIL_REGEX.test(val)),
      password: Yup.string()
        .min(2, 'Password too Short!')
        .max(50, 'Password too Long!')
        .required('Required'),
    }),
  });

  return (
    <div className="account-pages d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <div className="bg-soft-primary">
                <Row>
                  <Col className="col-7">
                    <div className="text-primary p-4">
                      <h5 className="text-primary">{titleCaseIfExists(userType)} Admin</h5>
                      <p>Sign in to continue</p>
                    </div>
                  </Col>
                  <Col className="col-5 align-self-end">
                    <img src={profileImg} className="img-fluid" />
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div className="p-2">
                  <Form className="form-horizontal" onSubmit={formik.handleSubmit}>
                    {loginErrorMsg && <Alert color="danger">{loginErrorMsg}</Alert>}
                    <FormGroup className="mt-2 mb-3">
                      <Label for="email">Email</Label>
                      <Input
                        type="text"
                        placeholder="Email"
                        {...formik.getFieldProps('email')}
                        invalid={!!(formik.touched.email && formik.errors.email)}
                      />
                      <FormFeedback>{formik.errors.email}</FormFeedback>
                    </FormGroup>
                    <FormGroup className="mt-4 mb-3">
                      <Label for="password">Password</Label>
                      <Input
                        style={{
                          position: 'relative',
                        }}
                        type={showHidePassword ? 'text' : 'password'}
                        placeholder="Enter Password"
                        {...formik.getFieldProps('password')}
                        invalid={!!(formik.touched.password && formik.errors.password)}
                      />
                      <i
                        style={{
                          position: 'absolute',
                          cursor: 'pointer',
                          right: '50px',
                          top: '46%',
                        }}
                        className={`${showHidePassword ? 'mdi mdi-eye-off' : 'mdi mdi-eye'}`}
                        onClick={() => setShowHidePassword(!showHidePassword)}
                      />
                      <FormFeedback>{formik.errors.password}</FormFeedback>
                    </FormGroup>
                    <div className="text-end">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={getCurrentUserData(userType)?.stgURL + '/forgot-password'}
                        className="text-muted ">
                        <i className="mdi mdi-lock mr-1" /> Forgot your password?
                      </a>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mt-3">
                      <div>
                        {SOURCE?.map((option, i) => (
                          <div key={`active_${i}`} className="form-check  me-3">
                            <input
                              type="radio"
                              id={`active_${option?.value}`}
                              name="babyname_active"
                              className="form-check-input"
                              checked={userType === option?.value}
                              onChange={() => setUserType(option?.value)}
                            />
                            <label className="form-check-label" htmlFor={`active_${option?.value}`}>
                              {option?.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* Not working as of now */}

                    <Button type="submit" color="primary" className="w-100 mt-3" block>
                      Log In
                    </Button>
                    <GoogleLoginComponent userType={userType} />
                  </Form>
                </div>
              </CardBody>
            </Card>

            <div className="mt-5 text-center">
              <a target="_blank" rel="noreferrer" href={'/branding'} className="text-primary text-center">
                View Branding Page
              </a>
              <p className="mt-2">© {new Date().getFullYear()} ParentsNKids.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};

export default Login;
