import React, { useState } from 'react';
import { API_BASE_URL, MODULE_TYPE } from '../../utils/constants';
import { api } from '../../utils/axios';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Loader from '../../components/Loader';
import { handleImageUploaded } from '../../utils/helper';

const ImageUpload = ({ isOpen, toggle, onUpload }) => {
  const [showModalLoading, setShowModalLoading] = useState(false);
  const [file, setFile] = useState();
  const [image, setImage] = useState();
  const handleFileChange = (e) => {
    if (e?.target?.files) {
      setFile(e.target.files[0]);
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleUpload = () => {
    if (file) {
      setShowModalLoading(true);
      const payload = {
        isAsset: true,
      };

      const formData = new FormData();
      formData.append('file', file);

      api({
        method: 'POST',
        url: `${API_BASE_URL}/attachment/upload?config=${btoa(JSON.stringify(payload))}`,
        data: formData,
      })
        .then(async (filePreview) => {
          const file = await handleImageUploaded(filePreview, 'acceptFile', MODULE_TYPE.TPZ_APP, false);
          onUpload(file);
          toggle();
        })
        .catch((err) => {
          console.warn('Error uploading file:', err);
        })
        .finally(() => {
          setShowModalLoading(false);
        });
    }
  };

  return (
    <>
      <Loader isActive={showModalLoading} />
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Upload Image</ModalHeader>
        <ModalBody className="p-4">
          <input type="file" value={file?.filename} onChange={handleFileChange} />
          {image && (
            <div className="image-preview position-relative me-2">
              <img
                src={image}
                alt="Selected"
                className="img-fluid rounded"
                style={{ maxWidth: '300px', maxHeight: '300px' }}
              />
              <button
                type="button"
                className="btn btn-danger btn-sm position-absolute top-0 left-0 start-100 translate-middle"
                onClick={() => {
                  setImage(null);
                  setFile({ filename: '' });
                }}
                style={{ borderRadius: '50%', padding: '2px', fontSize: '10px' }}>
                <i className="mdi mdi-close"></i>
              </button>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpload} disabled={!file}>
            Upload
          </Button>
          <Button color="light" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ImageUpload;
