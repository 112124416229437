export const NUTRITIONS_GRAPHQL_URL = {
  context: {
    uri: process.env.REACT_APP_NUTRITION_GRAPHQL_BACKEND_BASE_URL,
  },
};
export const COMMUNITY_GRAPHQL_URL = {
  context: {
    uri: process.env.REACT_APP_COMMUNITY_GRAPHQL_BACKEND_BASE_URL,
  },
};
