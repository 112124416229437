import SmkAsyncSelectGraphql from 'components/SmkAsyncSelect/customSelectGraphql';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardTitle, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { FOOD_ITEMS_SEARCH } from '../graphql/food_item.query';
import SubstituteCard from './SubstituteCard';

const IngredientsCard = ({ formik, formikLabel, values }) => {
  toast.configure();

  const defaultIngredient = {
    foodItemId: null,
    quantity: '',
    quantityUnit: '',
    preparationState: '',
    condition: '',
    isOptional: false,
    substitutes: [],
  };

  const [ingredients, setIngredients] = useState([defaultIngredient]);

  //   set formik values
  useEffect(() => {
    if (!isEmpty(values)) {
      setIngredients(values);
    }
  }, [values]);

  useEffect(() => {
    formik.setFieldValue(formikLabel, ingredients);
  }, [ingredients]);

  const handleInputChange = (e, key, index, isData) => {
    let fieldValue = '';
    if (isData) {
      fieldValue = e;
    } else {
      const { value, type, checked } = e.target;
      fieldValue = type === 'checkbox' ? checked : value;
    }

    const updatedIngredients = ingredients?.map((item, i) => (i === index ? { ...item, [key]: fieldValue } : item));
    setIngredients(updatedIngredients);
  };

  const handleAddIngredient = () => {
    const updatedData = [...ingredients, defaultIngredient];
    setIngredients(updatedData);
  };

  const handleDeleteIngredient = (index) => {
    setIngredients((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="mb-5">
      {!isEmpty(ingredients) &&
        ingredients.map((ingredient, index) => (
          <Card key={index}>
            <CardBody>
              <CardTitle className="mb-4">
                <div className="d-flex align-items-center justify-content-between border-bottom pb-3">
                  <h4 className="mb-0">Ingredient {index + 1} </h4>
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      handleDeleteIngredient(index);
                    }}>
                    Delete
                  </button>
                </div>
              </CardTitle>
              <Form className="mb-3">
                <Row>
                  <Col md={12}>
                    <FormGroup check className="mb-4">
                      <Label check>
                        <Input
                          type="checkbox"
                          name="isOptional"
                          checked={ingredient.isOptional}
                          onChange={(e) => handleInputChange(e, 'isOptional', index)}
                        />
                        Is Optional
                      </Label>
                    </FormGroup>
                  </Col>

                  <Col md={12}>
                    <FormGroup className="mb-4">
                      <Label for="foodItemId">Food Item</Label>
                      <SmkAsyncSelectGraphql
                        acceptedKey="name"
                        acceptedValue="id"
                        placeholder="Type food item name..."
                        searchKeyName="name"
                        query={FOOD_ITEMS_SEARCH}
                        queryName="admin_nutrition_foodItem_search"
                        dtoName="searchDTO"
                        filters={{
                          autoComplete: true,
                          active: true,
                          page: 1,
                          size: 500,
                          sortBy: 'id',
                          sortOrder: 0,
                          language: formik.values.language,
                        }}
                        value={
                          !isEmpty(ingredient?.foodItem?.name)
                            ? {
                                label: ingredient?.foodItem?.name,
                                value: ingredient?.foodItem?.slug,
                              }
                            : null
                        }
                        onChange={(data) => {
                          const updatedObj = {
                            label: data?.name,
                            value: data?.slug,
                            name: data?.name,
                            slug: data?.slug,
                            id: data?.id,
                          };
                          handleInputChange(updatedObj, 'foodItem', index, true);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label for="quantity">Quantity</Label>
                      <Input
                        type="text"
                        name="quantity"
                        placeholder="Enter quantity"
                        value={ingredient.quantity}
                        onChange={(e) => handleInputChange(e, 'quantity', index)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label for="quantityUnit">Quantity Unit</Label>
                      <Input
                        type="text"
                        name="quantityUnit"
                        placeholder="Enter quantity unit"
                        value={ingredient.quantityUnit}
                        onChange={(e) => handleInputChange(e, 'quantityUnit', index)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label for="preparationState">Preparation State</Label>
                      <Input
                        type="text"
                        name="preparationState"
                        placeholder="Enter preparation state"
                        value={ingredient.preparationState}
                        onChange={(e) => handleInputChange(e, 'preparationState', index)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label for="condition">Condition</Label>
                      <Input
                        type="text"
                        name="condition"
                        placeholder="Enter condition"
                        value={ingredient.condition}
                        onChange={(e) => handleInputChange(e, 'condition', index)}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={12}>
                    <div className="bg-light rounded-2 p-3">
                      <SubstituteCard
                        className={'bg-light p-0'}
                        onChange={(data) => handleInputChange(data, 'substitutes', index, true)}
                        initialValues={ingredient.substitutes}
                        filters={{
                          language: formik.values.language,
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        ))}

      <Button color="primary" onClick={handleAddIngredient}>
        Add Ingredient
      </Button>
    </div>
  );
};

export default IngredientsCard;
